


.Carosel{
    max-width: 1024px;
    display: flex;
    flex-direction: row;
    color: none;
    
    
    
}

.row{
    display: flex;
    flex-direction: column;
    padding: 2.5%;
}
.rowtop{
    display: flex;
    flex-direction: column;
  

}

.rowbottom{
    display: flex;
    
    flex-direction: row;
}

.photos2 {
    align-items: center;
    width: 32%;
    border-radius: 15px;
    border: 1px solid black;
    vertical-align: middle;
}

.photos2.mid{
    margin:  0% 2% 0% 2%;
}
.photos2.photosblock{
    visibility: hidden;
}

.scrollbuttonpan{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 3px;

}

.scrollbutton{
    background:rgb(139, 0, 0);
    border: none;
    padding: 10px 10px;
    margin: 0px 5px 0px 5px;
    text-align: center;
    text-decoration: none;

    outline: none; 
    border-radius: 20px;
  

}

.scrollbuttonSelc{
    outline: none;
    background:rgb(168, 84, 84);
    border: none;
    padding: 10px 10px;
    margin: 0px 5px 0px 5px;
    text-align: center;
    text-decoration: none;
    
    outline: none; 
    border-radius: 20px;
}

.navButtonPan{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.navButton{
    background-color: rgb(170, 170, 170);
    border-radius: 50px;
    padding: 12px 18px;
    font-size: 25px;
    border: none;
    outline: none; 
}

.blogframe{
    background-color: rgb(223, 223, 223);
    border-radius: 20px;
    border: 1px solid black;
    outline: none; 
    max-height: 410px;

    margin:0px 10px 10px 10px; 
}
.h1{
    margin-top: 0px;
}

.p{
    margin: 0px;
}
.p2{
    margin-top: 10px;
    margin-bottom: 0px;
    height: 63px;
    overflow: hidden;

}
.link{
    margin-bottom: 5px;
    color: rgb(139, 0, 0);
    background-color: rgb(223, 223, 223);
    font-weight: bold;
}

.navButton:hover{
    background-color: rgb(207, 207, 207);
}

@media screen and (max-width: 600px) {
    .blogframe{
        max-height: fit-content;
       
    }
  
    .navButton{
        visibility: hidden;
        border-radius: 00px;
        padding: 0px 0px;
        font-size: 0px;
    }
  
    
    .photos2 {
        align-items: center;
        width: 100%;
        border-radius: 15px;
        border: 1px solid black;
        vertical-align: middle;
    }
    
    .photos2.mid{
        margin:  10px 0% 10px 0%;
        visibility: hidden;
    }
    .photos2.last{
        visibility: hidden;
    }
    .photos2.photosblock{
        visibility: hidden;
        
    }
}


.fade-enter .blogframe {
    opacity: .1;
    transform: translateX(-100%);
  }
  .fade-enter-active .blogframe {
    opacity: 1;
    transform: translateX(0%);
  }

  .fade-enter-done .blogframe {
    opacity: 1;
    transform: translateX(0%);
  }
  .fade-exit .blogframe {
    opacity: 1;
    transform: translateX(0%);
  }
  .fade-exit-active .blogframe {
    opacity: .1;
    transform: translateX(100%);
  }

  .fade-enter-active .blogframe,
  .fade-exit-active .blogframe {
    transition: opacity 400ms, transform 600ms;
  }
