.info{
  margin-top: 85px;
  padding-top: 20px;
  text-align: justify;
  height: 100%; 
  min-height: 100vh; 
  padding: 0% 5% 5% 5%;
  overflow: hidden;
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  z-index: 1;
}

.info2{
  margin-top: 120px;
  margin-bottom: 20px;
  padding: 0 5%;

}

.info.home {
  margin-top: 0;
  min-height: 10vh;
}

.info3 {
  min-height: 100vh;
  padding: 0 5% 5%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
}

.subinfo {
  overflow: hidden;
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  max-height: inherit;

}

.column.full{
  width: 100%;
  margin-right:0;
  align-content: center;
  align-items: stretch;
  align-self: center;
  vertical-align: middle;
}

.column.leftpan{
  width:60%;
  text-align: justify;
  align-items: stretch;
  margin-right: 5%;
}
.column.rightpan{
  width:35%;
  align-content: center;
  align-items: stretch;
  align-self: center;
  vertical-align: middle;
  
}

.page {
  max-width: 1024px;
  margin: auto;
}


/* Responsive layout - makes the three columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column.leftpan, .column.rightpan {
    width: 100%;
    margin-right:0;
  }
}


.infoold{
  
  padding-top: 100px;
  text-align: justify;
  height: 100%; 
  min-height: 100vh; 
  padding: 5%;
}

.photos {
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 15px;
  border: 1px solid black;
  vertical-align: middle;
}

.photos.large {
  max-width: 70vw;
  display: table;
  margin: 0 auto;
}

@media screen and (max-width: 600px) {
  .photos.large {
  max-width: 100%;
    margin-right:0;
  }
}
