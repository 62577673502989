
.footer{
    padding: 10px 0 10px 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #20232a;
    color: #999;
    text-align: center;
   
}