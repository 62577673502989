.logo-columns {
    margin-top: 0px;
    margin-left: 0px;
}

.page2{
  width: 1024px;
  margin: auto;
}

.logo{
    height: auto;
    width: 15%;
    max-width: 110px;
    min-width:80px;
    border-radius: 15px;
    background: white;
    padding: 5px;
    margin: 0px 5px 0px 5px;
    border: 1px solid black;
    box-shadow: 5px 5px 5px rgba(148, 84, 84, 0.452);
    vertical-align: middle;
}

.header {
    display: flex;
    overflow: hidden;
    padding: 0.75%;

    margin: 0px;
    background:rgb(139, 0, 0);
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.4);
    z-index: 1;

}
.ribbinbuttons{
    background:none;
    color: ivory;
    border: none;
    align-items: center;
    align-content:center;
    display: flex;
    align-self: center;
    vertical-align: middle;
    z-index: 1;
    
}

.headerbuttons{
    background:none;
    color: ivory;
    border: none;
    padding: 15px 32px;
    margin: 0px 5px 0px 5px;
    text-align: center;
    text-decoration: none;
    display: inline;
    font-size: 20px;
    outline: none;    
}

.headerbuttons-drop{
  display:flex;
}
.headerbuttons:hover{
    background:none;
    color: rgb(209, 3, 3);
    border: none;
    padding: 15px 32px;
    margin: 0px 5px 0px 5px;
    text-align: center;
    text-decoration: none;
    font-size: 20px;
    outline: none;  
}
.headerbuttonsSelc{
    outline: none;
    background:rgb(168, 84, 84);
    color: ivory;
    padding: 15px 32px;
    margin:  0px 5px 0px 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 15px;
    border: none;
}
.headerbuttonsSelc-drop{
  display:flex;
}

.headerbuttonsSelc:hover{
    outline: none;
    background:rgb(168, 84, 84);
    color: rgb(209, 3, 3);
    padding: 15px 32px;
    margin: 0px 5px 0px 5px;
    text-align: center;
    text-decoration: none;
    font-size: 20px;
    border-radius: 15px;
    border: none;
}

.fa {
    padding: 10px;
    margin-right: 10px;
    width:30px;
    height: 30px;
    text-align: right;
    border-radius: 40%;
    vertical-align: middle;
    align-items: stretch;
  }

.fa2 {
    display: flex;
    justify-content: flex-end;
    align-items: right;
    margin-right: 0;
    margin-left: auto;

}


/*float: left;
overflow: hidden;*/

.dropdown {

    background:none;
    color: ivory;
    border: none;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    outline: none;  
    padding-bottom: 2px;
  }
  

  
  .navbar a:hover, .dropdown:hover .dropbtn {
    background-color: red;
  }
  
  .dropdown-content {
    display: none;
    position: fixed;
    background:rgb(139, 0, 0);
    min-width: 160px;
    padding: 1%;
    margin-top: 2px;
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.4);
    border-radius: 15px 15px 15px 15px;
    
  }
  
  
  
  
  .dropdown:hover .dropdown-content {
    display: flex;/*list-item;*/
    flex-flow: column wrap; 
    flex-direction: column;
  
    z-index: 2;
  }

  .hambug{
    width: 30px;
    height: 3px;
    background-color: ivory;
    margin: 6px 0;
  }
  @media (max-width: 600px) {
    .hide-mobile,.fa2 {
      display: none;
    }
    .page2{
      width: 100%;
    }
    .header{
      max-width: 600px;
      padding: 5px;
      
    } 
    .logo{
      height: 50px;
      width: 102px;
    }
  

  }
  @media not all and (max-width: 600px) {  
    .hide-desktop {
      display: none;
    }
  }