

.bloglook{
    background-color: rgb(223, 223, 223);
    border-radius: 20px;
    border: 1px solid black;
    outline: none; 
    margin-bottom: 30px;
}

.h1{
    margin-top: 0px;
}